import React             from 'react';
import Img               from 'gatsby-image';
import styled            from '@emotion/styled';
import { useMediaQuery } from '@chakra-ui/react';

import Link from '@interness/web-core/src/components/elements/Link/Link';

const ContactButton = styled(Link)`
  padding: 0 30px 0 30px;
  height: 40px;
  display: inline-flex;
  color: #f8f8f8;
  border: 1px solid #f8f8f8;
  border-radius: 20px;
  align-items: center;
  white-space: nowrap;
  justify-content: center;

  &:hover {
    background-color: #f8f8f8;
    color: #127749;
    border-color: #127749;
  }
`

const Container = styled.div`
  position: relative;

  .int-text {
    h2, h5 {
      color: #f8f8f8 !important;
    }
    position: absolute;
    top: 50%;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;

    h5 {
      font-weight: bold;
      font-size: 1.1em;
    }

    h2 {
      font-size: 2.4em;
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  .int-bg-image {
    ::after {
      content: '';
      background-color: #000000;
      opacity: 0.2;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
    }
  }
`


const ContactPush = ({ title, name, imageSet }) => {
  const [portrait] = useMediaQuery('(max-width: 768px)');
  return (
    <Container>
      <div className="int-text">
        <h5>{title}</h5>
        <h2>{name}</h2>
        <ContactButton to="/uhren/rolex/kontakt">Kontakt</ContactButton>
      </div>
      <div className="int-bg-image">
        <Img fluid={portrait ? imageSet.portrait : imageSet.landscape}/>
      </div>
    </Container>
  )
};

export default ContactPush;