import React                          from 'react';
import Img                            from 'gatsby-image';
import { graphql, useStaticQuery }    from 'gatsby';
import { Center, Box, useMediaQuery, Heading } from '@chakra-ui/react';

import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Mounted from '@interness/web-core/src/components/structure/Mounted/Mounted';

import RolexLayout   from '../../../components/Layout';
import Tracking      from '../../../components/Tracking';
import TwoColumn     from '../../../components/TwoColumn';
import Checkboard    from '../../../components/Checkboard';
import ContactButton from '../../../components/ContactButton';
import KeepExploring from '../../../components/KeepExploring';
import ContactPush   from '../../../components/ContactPush';


const RolexWartungPage = () => {
  const data = useStaticQuery(query);
  const [portrait] = useMediaQuery('(max-width: 768px)');
  const name = data.directusCompany.display_name;

  const media = {
    cover: {
      portrait: data.coverPortrait.childImageSharp.fluid,
      landscape: data.coverLandscape.childImageSharp.fluid,
    },
    banner: {
      portrait: data.bannerPortrait.childImageSharp.fluid,
      landscape: data.bannerLandscape.childImageSharp.fluid,
    },
    verification: {
      portrait: data.verificationPortrait.childImageSharp.fluid,
      landscape: data.verificationLandscape.childImageSharp.fluid,
    },
    centre: {
      portrait: data.centrePortrait.childImageSharp.fluid,
      landscape: data.centreLandscape.childImageSharp.fluid,
    },
    procedure: {
      portrait: data.procedurePortrait.childImageSharp.fluid,
      landscape: data.procedureLandscape.childImageSharp.fluid,
    },
    guarantee: {
      portrait: data.guaranteePortrait.childImageSharp.fluid,
      landscape: data.guaranteeLandscape.childImageSharp.fluid,
    },
    contact: {
      portrait: data.contactPortrait.childImageSharp.fluid,
      landscape: data.contactLandscape.childImageSharp.fluid,
    },
    plaque: {
      portrait: data.plaquePortrait.childImageSharp.fixed,
      landscape: data.plaqueLandscape.childImageSharp.fixed,
    },
  }

  return (
    <RolexLayout>
      <Tracking pageType={'servicing your rolex page'}/>
      <Mounted>
        <section>
          <Img width="100%" height="25vh"
               fluid={portrait ? media.cover.portrait : media.cover.landscape}/>
        </section>
        <Spacer/>
        <section>
          <Center width="90%" margin="auto">
            <Box maxW="650px" w="100%" textAlign="center">
              <Box textTransform="uppercase">
                <p><b>Geschaffen, um die Zeit zu überdauern</b></p>
                <Box sx={{
                  letterSpacing: '4.5px',
                  fontWeight: '300',
                  lineHeight: '1.2',
                }}>
                  <Heading as="h1" size='lg' sx={{ hyphens: 'auto' }}>Wartung und Reparatur Ihrer Rolex Uhr bei {name}</Heading>
                </Box>
              </Box>
              <p>
                Rolex setzt bei der Konstruktion und Fertigung seiner Armbanduhren auf Beständigkeit. Qualität ist daher
                das oberste Gebot in jeder Etappe der Entwicklung und der Fertigung einer Rolex Armbanduhr von der
                Konstruktion bis zum Verlassen der Produktion. Jede Arm-banduhr mit der legendären Krone kann so ihre
                Funktionen vollumfänglich ohne zeitliche Beschränkung erbringen und von Generation zu Generation
                weitergegeben werden – eine Uhr mit mehreren Leben.
              </p>
            </Box>
          </Center>
        </section>
        <Spacer/>
        <section>
          <Box width="90%" maxW="1140px" textAlign="center" margin="auto">
            <Img fluid={portrait ? media.banner.portrait : media.banner.landscape}/>
          </Box>
        </section>
        <Spacer/>
        <section>
          <Center width="90%" margin="auto">
            <Box maxW="650px" w="100%" textAlign="center">
              <h2>VERTRAUEN SIE UNSEREN EXPERTEN</h2>
              <p>Da zwischen zwei Wartungen viele Jahre liegen, verdient Ihre Armbanduhr größte Aufmerksamkeit bis ins
                Detail. Als autorisiertes Rolex Servicezentrum ist {name} voll und ganz bestrebt, Ihnen
                einen erstklassigen Kundenservice zu bieten. Dank der hohen Ansprüche und des Know-hows unserer
                Uhrmacher
                besticht Ihre Rolex Armbanduhr auch nach Jahren noch durch ausgezeichnete Zuverlässigkeit und makellosen
                Glanz.</p>
            </Box>
          </Center>
        </section>
        <Spacer/>
        <section>
          <TwoColumn title={`WARTUNG IHRER ROLEX BEI ${name}`} imageSet={media.verification}>
            {name} ist stolz, Teil des weltweiten Netzwerks von Rolex ausgebildeter Uhrmacher zu sein,
            die sorgfältig aufgrund ihrer Professionalität und fachlichen Kompetenz ausgewählt werden. Wir befolgen das
            Rolex Wartungsverfahren, das sicherstellt, dass jeder Zeitmesser, der eine Rolex Werkstatt verlässt, seine
            ursprünglichen funktionellen und ästhetischen Merkmale aufweist.
          </TwoColumn>
        </section>
        <Spacer/>
        <section>
          <Checkboard title={'IHR AUTORISIERTES SERVICEZENTRUM'} imageSet={media.centre} logoSet={media.plaque}
                      inverted>
            In unseren hochmodernen Servicezentren arbeiten nach strengen, von Rolex regelmäßig überprüften
            Exzellenzkriterien ausgebildete Uhrmacher. Alle Wartungsmaßnahmen für Ro-lex Armbanduhren – von der
            Längenanpassung eines Armbands bis hin zur umfassenden Wartung – können {name} anvertraut
            werden.
          </Checkboard>
          <Checkboard title={'DAS ROLEX WARTUNGSVERFAHREN'} imageSet={media.procedure}>
            Der hohe Anspruch an die Qualität einer Wartungsdienstleistung von Rolex stellt sicher, dass jeder
            Zeitmesser,
            der unsere Werkstätten verlässt, seine ursprünglichen funktionellen und ästhetischen Merkmale aufweist.
            Jedes
            Rolex Manufakturwerk wird einem Komplettservice unterzogen und sowohl Gehäuse als auch Armband werden mit
            äußerster Sorgfalt aufgefrischt, um den außergewöhnlichen Glanz wiederherzustellen. Die Ganggenauigkeit und
            die Wasserdichtheit aller Modelle werden nach strengsten Kriterien geprüft, um die Qualität und die
            Zuverlässigkeit zu garantieren, die Sie von einer Rolex Armbanduhr erwarten dürfen.
            <br/>
            <Spacer height={20}/>
            <ContactButton to="/uhren/rolex/wartungsverfahren" primary>Erfahren Sie mehr</ContactButton>
          </Checkboard>
          <Checkboard title={'ZWEIJÄHRIGE SERVICEGARANTIE'} imageSet={media.guarantee} inverted>
            Nach einem Komplettservice verfügt Ihre Rolex über eine zweijährige Servicegarantie. Von dieser Garantie
            ausgeschlossen sind Verschleißerscheinungen und Beschädigungen, die auf einen Unfall oder unsachgemäße
            Behandlung der Rolex Armbanduhr zurückzuführen sind. Bei Eingriffen durch nicht autorisierte Dritte sowie
            bei
            Hinzufügen von Ersatzteilen, die nicht Originalersatzteile von Rolex sind, verfällt die Servicegarantie.
          </Checkboard>
        </section>
        <Spacer/>
        <section>
          <ContactPush title="Wartung Ihrer Rolex" name={name} imageSet={media.contact}/>
        </section>
        <Spacer/>
        <section>
          <KeepExploring/>
        </section>
        <Spacer/>
      </Mounted>
    </RolexLayout>
  )
};

export default RolexWartungPage;

const query = graphql`
    query {
        directusCompany {
            display_name
        }
        coverLandscape: file(name: {eq: "servicing-your-rolex-cover"} relativeDirectory: {eq: "landscape/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100, maxWidth: 2880) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        coverPortrait: file(name: {eq: "servicing-your-rolex-cover_portrait"} relativeDirectory: {eq: "portrait/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100, maxWidth: 750) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bannerLandscape: file(name: {eq: "servicing-your-rolex-authorised-service-centre"} relativeDirectory: {eq: "landscape/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bannerPortrait: file(name: {eq: "servicing-your-rolex-authorised-service-centre_portrait"} relativeDirectory: {eq: "portrait/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        verificationLandscape: file(name: {eq: "servicing-your-rolex-verification"} relativeDirectory: {eq: "landscape/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        verificationPortrait: file(name: {eq: "servicing-your-rolex-verification_portrait"} relativeDirectory: {eq: "portrait/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        centreLandscape: file(name: {eq: "servicing-your-rolex-your-authorized-service-centre"} relativeDirectory: {eq: "landscape/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        centrePortrait: file(name: {eq: "servicing-your-rolex-your-authorized-service-centre_portrait"} relativeDirectory: {eq: "portrait/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        procedureLandscape: file(name: {eq: "servicing-your-rolex-procedure"} relativeDirectory: {eq: "landscape/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        procedurePortrait: file(name: {eq: "servicing-your-rolex-procedure_portrait"} relativeDirectory: {eq: "portrait/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        guaranteeLandscape: file(name: {eq: "servicing-your-rolex-two-year-service-guarantee"} relativeDirectory: {eq: "landscape/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        guaranteePortrait: file(name: {eq: "servicing-your-rolex-two-year-service-guarantee_portrait"} relativeDirectory: {eq: "portrait/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        contactLandscape: file(name: {eq: "servicing-your-rolex-contact-push"} relativeDirectory: {eq: "landscape/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        contactPortrait: file(name: {eq: "servicing-your-rolex-contact-push_portrait"} relativeDirectory: {eq: "portrait/servicing"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        plaqueLandscape: file(name: {eq: "rolex-service-plaque-500x250_de"} relativeDirectory: {eq: "landscape"}) {
            name
            childImageSharp {
                fixed(quality: 100, width: 120) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        plaquePortrait: file(name: {eq: "rolex-service-plaque-240x120_de"} relativeDirectory: {eq: "portrait"}) {
            name
            childImageSharp {
                fixed(quality: 100, width: 120) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;